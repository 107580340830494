import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Collapse, Typography, useTheme} from '@mui/material';
import {ArrowDropDown, ArrowRight} from '@mui/icons-material';

const StyledWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    margin-top: ${props => props.theme.spacing(props.mt)};
    margin-bottom: ${props => props.theme.spacing(props.mb)};
`;

const StyledTitle = styled.div`
    padding-bottom: 0.25rem;
`;

const StyledCollapse = styled(Collapse)`
    width: 100%;
`;

export default function AdvancedSectionExpansion(props) {

    const theme = useTheme();
    const [showAdvanced, setShowAdvanced] = useState(props.open);

    const arrowOptions = {
        color: 'primary',
    };
    let optionArrowIcon;
    if (showAdvanced) {
        optionArrowIcon = <ArrowDropDown {...arrowOptions} />
    } else {
        optionArrowIcon = <ArrowRight  {...arrowOptions} />
    }
    
    // noinspection MagicNumberJS
    return <>
        <StyledWrapper onClick={() => setShowAdvanced(!showAdvanced)} theme={theme} mt={props.mt} mb={props.mb}>
            <div>{optionArrowIcon}</div>
            <StyledTitle>
                <Typography style={{alignItems: 'flex-start', ...props.textStyle}} component={'span'}
                            variant={props.variant}>{props.title}
                </Typography>
            </StyledTitle>
        </StyledWrapper>
        <StyledCollapse in={showAdvanced} timeout={450}>
            {props.children}
        </StyledCollapse>
    </>
}

AdvancedSectionExpansion.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.string,
    textStyle: PropTypes.object,
    mt: PropTypes.number, // margin-top
    mb: PropTypes.number, // margin-bottom
};

AdvancedSectionExpansion.defaultProps = {
    open: false,
    title: 'Advanced Options',
    variant: 'body2',
    mt: 0,
    mb: 0,
};
