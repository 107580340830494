const Feature = ({name, children}) => {
    const features = JSON.parse(localStorage.getItem('flags'));
    const feature = features.find(feature => feature.name === name);

    if (feature && feature.active) {
        return children;
    }

    return null;
};

export default Feature;